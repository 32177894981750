* {
  margin: 0;
  padding: 0;
}

[id*="btn"] {
  border: none;
  height: 2rem;
  width: 2rem;
  font-size: 1rem;
}

#btn_night {
  margin-top: 1rem;
  margin-right: 1rem;
  float: right;
  border-radius: 1rem;
}

[id*="p"] {
  margin-left: 0.5rem;
}
